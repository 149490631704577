import {
  accessory,
  accessoryQuantity,
  activeCountryCode,
} from "@components/WalletForm/models/products.ts";
import { useState } from "preact/hooks";
import classNames from "classnames";
import s from "./CardholdersButton.module.scss";
import { useComputed } from "@preact/signals";
import { warehouseMap } from "../../../data/warehouses.ts";
import tickIcon from "../../../../public/img/promotions/tick.svg";
import plusIcon from "../../../../public/img/promotions/plus.svg";

export const CardholderButton = ({ soldOutText, addText, addedText }) => {
  const [isAdded, setIsAdded] = useState(false);

  const isSoldOut = useComputed(() => {
    const currentCountry = activeCountryCode.value;
    const warehouse = warehouseMap[currentCountry];
    if (!currentCountry) return false;
    return !accessory.value?.inventory_details?.[warehouse]?.is_available;
  });

  const toggleAdd = () => {
    setIsAdded((prevIsAdded) => {
      accessoryQuantity.value = prevIsAdded ? 0 : 1;
      return !prevIsAdded;
    });
  };

  return isSoldOut.value ? (
    <div className={classNames(s.cardholderButton, s.cardholderButtonSoldOut)}>
      <span>{soldOutText}</span>
    </div>
  ) : (
    <button
      id="cardholder-button"
      className={classNames(s.cardholderButton, {
        [s.cardholderButtonAdded]: isAdded,
        [s.cardholderButtonAdd]: !isAdded,
      })}
      type="button"
      onClick={toggleAdd}
      value={`${isAdded}`}
    >
      <span>{isAdded ? addedText : addText}</span>
      <img
        alt=""
        src={isAdded ? tickIcon.src : plusIcon.src}
        width="20"
        height="20"
      />
    </button>
  );
};
